import React from "react";
import Doctor from "../Assets/Assets/doctor-group.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title"> 
          <span>About Us</span>
        </h3>
        <p className="about-description">
          Welcome to Fortis OP Jindal Hospital & Research Centre, your trusted partner for accessible and
          personalized healthcare. Our expert doctors offer consultations
          and specialized services, prioritizing your well-being. Join us on
          this journey towards a healthier you.
        </p>

        <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="VISION"
          description="... Is to save & enrich lives through quality healthcare"/>

        <SolutionStep
          title="MISSION"
          description="FOPJHRC mission is to be a leading healthcare or organization known for Distinctive patient care and 
          Following highest standards of medical ethics and Creating positive impact on communities that we treat
          "/>

        <SolutionStep
          title="CORE VALUES"
          description="Our experienced doctors and specialists are here to provide expert advice and personalized treatment plans, helping you achieve your best possible health."
        />
      </div>
    </div>
  );
}

export default About;
