// Welcome.js

export default function Welcome(){
	return(
		<div className="bg-gray-200 px-4 md:px-8 lg:px-24 py-4 md:py-8 h-auto md:h-40">
    <h1 className="text-center text-slate font-semibold text-3xl md:text-4xl lg:text-5xl text-blue-500">
        Fortis OP Jindal Hospital & Research Centre
    </h1>
</div>
	)
}
