import './gal.css'
import React from "react";
import { infaImage } from "../../dataForAll/data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const Gal = () => {
  
  const slider = React.useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint:  2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <section>
    <div className="title">
    <span>Gallery</span>
    </div>
      <div className="infa-card-container">
        <Slider ref={slider} {...settings}>
          {infaImage.map((item) => {
            return (
              <div className="infa-card" key={item.id}>
                  <img src={item.img} />
                  
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="navigator-btn">
        <button className="navigator-prev" onClick={() => slider?.current?.slickPrev()}><FontAwesomeIcon icon={faChevronLeft} /></button>
        <button className="navigator-next" onClick={() => slider?.current?.slickNext()}><FontAwesomeIcon icon={faChevronRight} /></button> 
      </div>
    </section>
  );
};

export default Gal