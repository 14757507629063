import './specialtieslist.css'
import { specialityPage } from '../../dataForAll/data' 
import { useParams } from 'react-router-dom';
import Specialties from '../../components/specialties/Specialties';



const SpecialityDetails = () => {
  const { id } = useParams()

  const doctor = specialityPage.find(doctor => doctor.id === id)

  return (
    <section className='main-container'>
      
      <section className='speciality-page'>
        <div className='speciality-detail'>
          <div className='speciality-img-container'>
            <img src={doctor.image} alt='image' className='speciality-img' />
            <p className='speciality-title'>{doctor.title}</p>
          </div>
          <div className='about-speciality'>
          <div className="about-speciality-title">
              <span>About {doctor.title}</span>
          </div>
            <p>{doctor.about}</p>
          </div>
        </div>
      </section>
      <section className='speciality-experts'>
        <Specialties/>
      </section>
     
    </section>
  )
}

export default SpecialityDetails