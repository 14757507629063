// Card.js

export default function Card() {
	return (
	  <div className="flex flex-col md:flex-row items-center justify-center md:space-x-8 mt-8 mx-4 md:mx-auto max-w-7xl">
		<div className="w-full md:w-96 bg-white rounded-xl shadow-2xl">
		  <div className="p-6">
			<h5 className="text-center mb-2 font-sans text-xl font-semibold text-blue-gray-900 antialiased">
			  Reach Us At
			</h5>
			<ul>
			  <li className="mt-2 flex items-center">
				<i className="fa fa-phone mr-2"></i>
				<span>+91 9981462800</span>
			  </li>
			  <li className="mt-2 flex items-center">
				<i className="fa fa-envelope mr-2"></i>
				<span>front.office.rgh@jindalsteel.com</span>
			  </li>
			  <li className="mt-2">
				<div className="flex items-center">
				  <i className="fa-solid fa-map-pin mr-2"></i>
				  <div>
					<span>Fortis OP Jindal Hospital & Research Centre</span>
					<br />
					<span className="pl-4">Kharsia Rd, Kokaditarai, Chhattisgarh 496001</span>
				  </div>
				</div>
			  </li>
			</ul>
		  </div>
		</div>
		<div className="w-full md:w-96 mt-8 md:mt-0 bg-white rounded-xl shadow-2xl">
		  <div className="p-6">
			<h5 className="text-center mb-2 font-sans text-xl font-semibold text-blue-gray-900 antialiased">
			  Branding & Collaboration
			</h5>
			<div className="flex items-center justify-center">
			  <i className="fa fa-handshake fa-2xl"></i>
			</div>
			<div className="mt-4">
			  <div className="flex items-center">
				<i className="fa fa-envelope mr-2"></i>
				<span>marketing.fopjhrc.rgh@jindalsteel.com</span>
			  </div>
			  <div className="mt-2">
				<div className="flex items-center">
				  <i className="fa-solid fa-map-pin mr-2"></i>
				  <div>
					<span>Fortis OP Jindal Hospital & Research Centre</span>
					<br />
					<span className="pl-5">Kharsia Rd, Kokaditarai, Chhattisgarh 496001</span>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	);
  }
