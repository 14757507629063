export const customerReviews = [
  {
    "name": "Tapeshwar",
    "message": "Management and facility are excellent.. Doctors and nursing staff is very dedicated and caring, food is good. . As facilities are good so experience was also good. Keep the same work."
  },
  {
    "name": "Sreeram Samji",
    "message": "Medical care is very good. monitoring and 24*7 accessibility in icu is commandable. all nursing staff have take good care of me visiting doctors attended regularly and kept track of my health well. food is good . housekeeping done regularly and thoroughly. overall experience in icu so far is pleasant , comfortable and impressive. sister kamleshwari, sasmita and rebaca , ravi , santoshcare me very well."
  },
  {
    "name": "Parshuram Dansena",
    "message": "I have consulted so many doctors but not satisfied and getting well but after getting treatment from dr sanjeeb i am getting better day by day. doctor is very good."
  },
  {
    "name": "Jabir Ali",
    "message": "Sonography staff is very good. Appreciaion for Dr Aabhas."
  },
  {
    "name": "Shahjha Begum",
    "message": "Facilities are very good. Dr Rupesh give his full time to us for the consultation and treatment. Shobha sister coordinated in good manner."
  },
  {
      "name": "Vikas Kumar",
      "message": "Appreciation for Dr Anish and Dr Shiv for their polite behaviour and all ccu nursing staff behaviour and care is too good. Ward boys also very good. Hk Also very good and Food also we got on time."
    },
    {
      "name": "Nirupama Harijan",
      "message": "Appreciation for seema sister, fully supportive and cooperative . All the service was excellent I pleased to have like the particular service."
    },
    {
      "name": "Fagu Ram Sahu",
      "message": "Special and heartfelt thanks to shobha toppo for the coordiantion and respect and to understand my pain and expectation for my problem.Hospital facilities are very good."
    },
    {
      "name": "Sandeep Sanwal",
      "message": "Dental staff is very nice. Dr Snehdeep madam giving best treatment. Experience is very nice.,"
    },
    {
      "name": "Upender Nand",
      "message": "Behaviour and counselling of doctor is excellent and way of treatent is excellent"
    },
];
