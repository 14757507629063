
import Welcome from '../../components/contactModul/Welcome';
import Card from '../../components/contactModul/CardC';


const ContactUs = () => {
  return (
    <section className='main-container'>
    
    <section className='contact'> 
    <Welcome/>
    <Card/>
    {/* <Feedback/> */}
    <div className="relative w-full max-w-screen-xl mx-auto mt-8">
  <div className="overflow-hidden relative" style={{paddingTop: "56.25%"}}>
    <iframe 
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9302.828540972028!2d83.3590140610842!3d21.92762778614647!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a27253e9ec65f9b%3A0xbe3ad0e050e54f7d!2sFortis%20OP%20Jindal%20Hospital%20%26%20Research%20Centre!5e0!3m2!1sen!2sin!4v1692704338551!5m2!1sen!2sin" 
      width="100%" 
      height="100%" 
      loading="lazy" 
      referrerPolicy="no-referrer-when-downgrade" 
      className="absolute top-0 left-0 w-full h-full"
      style={{marginTop:"14%", marginLeft:"50%", transform: "translateX(-50%)"}}
      title="Google Map"
    ></iframe>
  </div>
</div>
    
    </section>
    <br/><br/><br/><br/><br/>
    
    </section>
  )
}

export default ContactUs