
import About from '../../components/aboutCompo/About'
import Doctors from '../../components/aboutCompo/Doctors'
import Hero from '../../components/aboutCompo/Hero'
import Info from '../../components/aboutCompo/Info'
import './aboutus.css'


const AboutUs = () => {
  return (
    <section className='main-container'>
    
    <Hero/>
    <Info/>
    <About/>
    <Doctors/>
   
    </section>
    
  )
}

export default AboutUs