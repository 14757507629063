import Home from './pages/home/Home'
import AboutUs from './pages/about us/AboutUs'
import Achievement from './pages/achievement/Achievement'
import ContactUs from './pages/contact us/ContactUs'
import SpecialtiesList from './pages/specialtieslist/SpecialtiesList'   
import SpecialityDetails from './pages/specialtieslist/SpecialityDetails'   
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DoctorsList from './pages/doctorslist/DoctorsList'
import DoctorDetails from './pages/doctorslist/DoctorDetails'
// import BlogPage from './pages/Blog Page/BlogPage'
import Careers from './pages/careers/Careers'
// import Developer from './pages/developers/Developer'
import ScrollToTop from './components/ScrollToTop'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

const App = () => {
 
  return (
    <BrowserRouter>
    <ScrollToTop>
    <Navbar/> 
        <Routes>
    
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/services" element={<Achievement />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/specialtieslist" element={<SpecialtiesList />} />
          <Route path="/specialtieslist/:id" element={<SpecialityDetails />} />
          <Route path="/doctorslist" element={<DoctorsList />} />
          <Route path="/doctorslist/:id" element={<DoctorDetails />} />
          {/* <Route path="/blogs-page" element={<BlogPage />} /> */}
          <Route path="/careers" element={<Careers />} />
          {/* <Route path="/developers" element={<Developer/>}/> */}

        </Routes>
    <Footer/>
    </ScrollToTop>
    </BrowserRouter>
  )
}

export default App;
