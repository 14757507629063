import React from "react";

import "./scard.css";

function ServCard() {
  return (
    <div className="info">
    <div className="textsa">
      <h2>
        <span>Fortis, O.P. Jindal</span>
        Hospital & Research Centre
      </h2>
      <p>
        A 100 bedded state-of-art tertiary care hospital providing a depth of expertise in the complete spectrum of advance medical and surgical care equipped with high end facilities. The hospital is dedicated to provide competitive, accessible and affordable world class healthcare services to tier- II city like Raigarh.
      </p>
    </div>
    <div className="boxes">
      <div className="row1">
        <div className="box1">
          <h3>Blood Bank</h3>
          <p>Blood & Component Bank (ISO recognized & only one in region to offer Component facility) | Pharmacy | Recovery Unit | Dialysis</p>
        </div>
        <div className="box2">
          <h3>Infrastructure</h3>
          <p>Minor Procedure Room | Only Apheresis Machine Unit | 4 bedded Emergency with 2 bedded Observation  | Ambulance (ACLS)
       </p>
        </div>
        <div className="box3">
          <h3>4 OTs</h3>
          <p>Specialty 1 | General 2| Eye 1 | Cath Lab | Minor OT</p>
        </div>
        <div className="box4">
          <h3>100 Beds</h3>
          <p> ICU (Medical/ Surgical/ Cardiac/ Burns/ Neonatal) | Single Rooms | Twin Sharing Wards | General Wards | Nursery</p>
        </div>
      </div>     
    </div>
  </div>
  );
}

export default ServCard;