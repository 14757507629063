
///////////////////////////////// Data for doctorsList ///////////////////////////////////////

export const doctorsList = [
  {
    id: "dr-ajay-gupta",
    image: require("../components/Assets/Assets/doctor photos/Dr._Ajay_Gupta__1.png"),
    name: "Dr. Ajay Gupta",
    department: "Medical Director Consultant Physician",
    Designation: "Internal Medicine"
  },
  {
    id: "dr-lokesh-mahendra",
    image: require("../components/Assets/Assets/doctor photos/Dr._Lokesh_Mahendra_1.png"),
    name: "Dr. Lokesh Mahendra",
    department: "Medical Superintendent",
    Designation: "Medical Admin"
  },
  {
    id: "dr-sib-kumar-padhi",
    image: require("../components/Assets/Assets/doctor photos/Dr._Sib_Kumar_Padhi__1.png"),
    name: "Dr. Sib Kumar Padhi",
    department: "Consultant Neuro Surgery",
    Designation: "Neuro Surgery"
  },
  {
    id: "dr-amit-sureshrao-ballamwar",
    image: require("../components/Assets/Assets/doctor photos/Dr._ Amit_Sureshrao_Ballamwar_1.png"),
    name: "Dr. Amit Sureshrao Ballamwar",
    department: "Consultant Cardiologist",
    Designation: "Interventional Cardiologist"
  },
  // {
  //   id: "de-rupesh-shrivastava",
  //   image: require("../components/Assets/Assets/doctor photos/Dr._Rupesh_Shrivastava__1.png"),
  //   name: "Dr. Rupesh Shrivastava",
  //   department: "Consultant Cardiology",
  //   Designation: "Cardiology"
  // },
  {
    id: "dr-mandeep-singh-tuteja",
    image: require("../components/Assets/Assets/doctor photos/Dr._Mandeep_Singh_Tuteja__1.png"),
    name: "Dr. Mandeep Singh Tuteja",
    department: "Consultant Pulmonology",
    Designation: "Pulmonology"
  },
  {
    id: "dr-amesh-kumar-rajak",
    image: require("../components/Assets/Assets/doctor photos/Dr._Amesh_Kumar_Rajak__1.png"),
    name: "Dr. Amesh Kumar Rajak",
    department: "General and Laparoscopic Surgeon",
    Designation: "Gen - Surgery"
  },
  {
    id: "dr-bharti-soy",
    image: require("../components/Assets/Assets/doctor photos/Dr._Bharti_Soy_1.png"),
    name: "Dr. Bharti Soy",
    department: "Consultant Obs & Gynae",
    Designation: "Obs & Gynae"
  },
  {
    id: "dr-santosh-kumar-das",
    image: require("../components/Assets/Assets/doctor photos/Dr._Santosh_Kumar_Das__1.png"),
    name: "Dr. Santosh Kumar Das",
    department: "Consultant Orthopedics",
    Designation: "Orthopedics"
  },
  {
    id: "dr-priyanka-shivappa",
    image: require("../components/Assets/Assets/doctor photos/Dr._Priyanka_Shivappa__1.png"),
    name: "Dr. Priyanka Shivappa",
    department: "Consultant ENT",
    Designation: "ENT"
  },
  {
    id: "dr-prateek-shivappa",
    image: require("../components/Assets/Assets/doctor photos/Dr._Prateek_Shivappa__1.png"),
    name: "Dr. Prateek Shivappa",
    department: "Consultant Pathology",
    Designation: "Pathology"
  },
  {
    id: "dr-aabhas-singh-sisodia",
    image: require("../components/Assets/Assets/doctor photos/Dr._Aabhas_Singh_Sisodiya__1.png"),
    name: "Dr. Aabhas Singh Sisodia",
    department: "Consultant Radiology",
    Designation: "Radiology"
  },
  {
    id: "dr-payal-gonde-ballamwar",
    image: require("../components/Assets/Assets/doctor photos/Dr._ Payal_Gonde_Ballamwar_1.png"),
    name: "Dr. Payal Gonde Ballamwar",
    department: "Consultant Ophthalmology",
    Designation: "Ophthalmology"
  },
  {
    id: "dr-avinash-rathore",
    image: require("../components/Assets/Assets/doctor photos/Dr._Avinash_Rathore__1.png"),
    name: "Dr. Avinash Rathore",
    department: "Consultant Oral & Maxillofacial Surgeon",
    Designation: "Oral & Maxillofacial Surgeon"
  },
  {
    id: "dr-shehdeep-chhabra-tuteja",
    image: require("../components/Assets/Assets/doctor photos/Dr._Snehdeep_Chabra_Tuteja_1.png"),
    name: "Dr. Snehdeep Chhabra Tuteja",
    department: "Consultant Dental",
    Designation: "Dental"
  },
  {
    id: "dr-chittranjan-haldar",
    image: require("../components/Assets/Assets/doctor photos/Dr._Chittranjan_Haldar__1.png"),
    name: "Dr. Chittranjan Haldar",
    department: "Pediatrician/ Medical Officer",
    Designation: "Pediatrician"
  },
  {
    id: "dr-dhananjay-patel",
    image: require("../components/Assets/Assets/doctor photos/Dr._Dhananjay_Patel__1.png"),
    name: "Dr. Dhananjay Patel",
    department: "Consultant Paediatrics",
    Designation: "Paediatrics"
  },
  {
    id: "dr-arjun-singh",
    image: require("../components/Assets/Assets/doctor photos/Dr._Arjun_Singh__1.png"),
    name: "Dr. Arjun Singh",
    department: "Critical Care",
    Designation: "ER"
  },
  {
    id: "dr-dharamendra-choubey",
    image: require("../components/Assets/Assets/doctor photos/Dr._Dharmendra_Choubey__1.png"),
    name: "Dr. Dharamendra Choubey",
    department: "Consultant Anaesthesiology & Intensive Care Unit",
    Designation: "Anaesthesia"
  },
  {
    id: "dr-pushpa-patel",
    image: require("../components/Assets/Assets/doctor photos/Dr._Pushpa_Patel__1.png"),
    name: "Dr. Pushpa Patel",
    department: "Consultant Anaesthesiology & Intensive Care Unit",
    Designation: "Anaesthesia"
  },
  {
    id: "dr-arnab-mukherjee",
    image: require("../components/Assets/Assets/doctor photos/Dr._Arnab_Mukherjee_1.png"),
    name: "Dr. Arnab Mukherjee",
    department: "CMO-ER",
    Designation: "ER"
  },
  {
    id: "dr-urchita-patel",
    image: require("../components/Assets/Assets/doctor photos/Dr._Urchita_Patel__1.png"),
    name: "Dr. Urchita Patel",
    department: "Dermatologist",
    Designation: "Dermatology"
  },
  ];

/////////////////////////// Data for doctorDetails ///////////////////////////

export const doctorDetails = [
  {
    id: "dr-ajay-gupta",
    image: require("../components/Assets/Assets/doctor photos/Dr._Ajay_Gupta__1.png"),
    name: "Dr. Ajay Gupta",
    department: "Medical Director Consultant Physician | Fortis Jindal",
    Designation: "Internal Medicine",
    status: "Full Time",
    education:"MBBS, MD(MED), Internal Medicine",
    about :["Dr. Ajay Gupta is a senior Physician from Chattisgarh, specialising in Internal Medicine. He has rich experience of more than 20 years in diagnosis and treatment of adult diseases.",

    "He is post graduate in Internal Medicine.",
    
    "Dr. Gupta is also working with Fortis OP Jindal Hospital as a Medical Director & Senior Consultant Physician.",

    "He is passionate about his profession and is famous for his humble approach towards his patients."]
  },
  {
    id: "dr-lokesh-mahendra",
    image: require("../components/Assets/Assets/doctor photos/Dr._Lokesh_Mahendra_1.png"),
    name: "Dr. Lokesh Mahendra",
    department: "Medical Suprintendent | Fortis Jindal",
    Designation: "Medical Admin",
    status: "Full Time",
    education: "MBBS, MS(Orthopaedics), Medical Administration",
    about :["Dr. Lokesh Mahendra is a highly esteemed medical professional with a rich and varied career in healthcare. Beginning his journey in 1988, he served as a Junior Resident at Safdarjang Hospital, where he laid the groundwork for his medical expertise.",

    "Continuing his career progression, Dr. Mahendra worked as a Consultant Specialist at UD Mission Hospital from 1993 to 1994, contributing his skills and knowledge to the hospital's healthcare services.",
    
    "From 1994 to 1997, he served as a Senior Resident at Hindu Rao GOV. Hospital in Delhi, further honing his clinical skills and gaining valuable experience in patient care.",
    
    "From 1997 to 2018, Dr. Mahendra held the position of Senior Specialist & Consultant at NTPC LTD., where he played a pivotal role in providing medical leadership and expertise to the organization.",
    
    "Transitioning to administrative roles, he served as Chief Medical Officer (CMO) at NTPC in Korba from 2018 to 2021, followed by a role as General Manager & CMO from 2021 to 2023, where he demonstrated his leadership skills and commitment to organizational healthcare management.",
    
    "In 2024, Dr. Mahendra took on the role of Medical Superintendent at Fortis O P Jindal Hospital & Research Center, bringing with him a wealth of experience and expertise. In this capacity, he oversees medical operations and ensures the delivery of high-quality healthcare services to patients, further solidifying his reputation as a leader in the healthcare field."]
  },
  {
    id: "dr-sib-kumar-padhi",
    image: require("../components/Assets/Assets/doctor photos/Dr._Sib_Kumar_Padhi__1.png"),
    name: "Dr. Sib Kumar Padhi",
    department: "Consultant Neuro Surgery | Fortis Jindal ",
    Designation: "Neuro Surgery",
    status: "Full Time",
    education: "MBBS, M.S, MCH(Neuro Surgery), Fellowship in Micro-Neurosurgery, Fellowship in Endoscopic Spine Surgery",
    about :["Dr. Sib Kumar Padhi is a highly accomplished medical professional with an extensive career spanning various prestigious institutions across India. His journey in healthcare began in 1998 when he served as a Medical Officer in a Government Hospital, where he gained invaluable experience in primary care and patient management.",

    "In 2004, Dr. Padhi embarked on a residency program at Nizams Institute in Hyderabad, furthering his expertise in his chosen field. He continued to refine his skills through subsequent residencies at Yasodha Hospital in Somajiguda, Hyderabad, from 2005 to 2006, and as a Senior Registrar at Gandhi Hospital in Hyderabad from 2009 to 2011.",
    
    "In 2011, Dr. Padhi assumed the role of Consultant at KIMS Hospital in Bhubaneswar, Odisha, where he provided specialized medical care to patients in need. He then served as a Junior Consultant at Apollo Hospital in Kakinada, Andhra Pradesh, from 2012 to 2013, contributing to the institution's commitment to excellence in healthcare delivery.",
    
    "In 2013, Dr. Padhi joined Fortis O P Jindal Hospital & Research Center in Raigarh, Chattisgarh, as a Consultant, where he played a pivotal role in advancing the hospital's mission of providing high-quality healthcare services to the community.",
    
    "Continuing his journey, he served as a Consultant at Yashoda Hospital in Hyderabad from 2014 to 2016, further solidifying his reputation as a skilled and compassionate healthcare provider.",
    
    "From 2016 to 2018, Dr. Padhi served as a Consultant at Princess ESRA Hospital and Professor at Deccan College of Medical Science in Hyderabad, where he contributed to medical education and patient care simultaneously, showcasing his dedication to both academia and clinical practice.",
    
    "Since 2018, Dr. Padhi has been serving as a Senior Consultant at Fortis O P Jindal Hospital & Research Center in Raigarh, where he continues to make significant contributions to the field of medicine, delivering expert care to patients in need."]
  },
  {
    id: "dr-amit-sureshrao-ballamwar",
    image: require("../components/Assets/Assets/doctor photos/Dr._ Amit_Sureshrao_Ballamwar_1.png"),
    name: "Dr. Amit Sureshrao Ballamwar",
    department: "Consultant Cardiologist | Fortis Jindal",
    Designation: "Interventional Cardiologist",
    status: "Full Time",
    education:"MBBS, MD, DNB, DrNB(Interventional Cardiologist)",
    about :["Dr. Amit Sureshrao Ballamwar is a highly skilled interventional cardiologist with a diverse background in medicine and patient care. He began his medical career with an internship under the Maharashtra Government from 2012 to 2013, which provided him with a solid foundation in various medical disciplines.",

             "Following his internship, Dr. Ballamwar served as a Medical Officer under the Maharashtra Government from 2013 to 2014, gaining valuable experience in clinical practice and patient management.",

             "He further advanced his expertise as a Senior Resident at Mahatma Gandhi Institute of Medical Sciences (MGIMS) in Sewagram, Maharashtra, from 2018 to 2019, where he focused on enhancing his skills in internal medicine and cardiology.",

             "In 2019, Dr. Ballamwar transitioned to a role as a Consultant Physician at Bhigwan Multispeciality Hospital in Pune, where he provided comprehensive care and managed a wide range of medical conditions.",

             "From 2020 to 2023, he worked as a Registrar in Cardiology, later becoming a Junior Consultant at Yashoda Hospital in Secunderabad, Telangana, where he specialized in cardiology, gaining significant experience in managing complex cardiac cases.",

             "Since 2024, Dr. Ballamwar has been serving as a Consultant Interventional Cardiologist at Fortis O.P. Jindal Hospital & Research Center. In this role, he brings his extensive experience and dedication to providing advanced cardiac care, focusing on interventional procedures to improve patient outcomes and enhance the quality of life for his patients."]
  },
  // {
  //   id: "dr-rupesh-shrivastava",
  //   image: require("../components/Assets/Assets/doctor photos/Dr._Rupesh_Shrivastava__1.png"),
  //   name: "Dr. Rupesh Shrivastava",
  //   department: "Consultant Cardiology | Fortis JIndal",
  //   Designation: "Cardiology",
  //   status: "Full Time",
  //   education: "MBBS, MD, DNB(Adult Cardiology)",
  //   about :["Dr. Rupesh Shrivastava is a distinguished medical professional with a wealth of experience in healthcare. His journey in medicine began in 2013, serving as a Resident at Jagjivan Ram Railway Hospital in Mumbai, where he gained invaluable clinical exposure and expertise.",

  //   "From 2016 to 2017, Dr. Shrivastava worked as a Junior Consultant at H.N. Reliance Hospital in Mumbai, contributing to the institution's commitment to excellence in patient care.",
    
  //   "Continuing his career progression, he served as a Registrar at the esteemed Asian Heart Institute & Research Center in Mumbai from 2017 to 2020. Here, Dr. Shrivastava played a vital role in managing complex medical cases and furthering his knowledge in cardiovascular medicine.",
    
  //   "In 2021, Dr. Shrivastava served as a Consultant at Disha Hospital in Jabalpur, where he continued to provide high-quality healthcare services to the community.",
    
  //   "Since 2022, Dr. Shrivastava has been serving as a Consultant at Fortis O P Jindal Hospital & Research Center, bringing his extensive expertise and dedication to patient care to the forefront. With a passion for healing and a commitment to excellence, Dr. Shrivastava continues to make a significant impact on the healthcare landscape, enriching the lives of his patients and the community at large."]
  // },
  {
    id: "dr-mandeep-singh-tuteja",
    image: require("../components/Assets/Assets/doctor photos/Dr._Mandeep_Singh_Tuteja__1.png"),
    name: "Dr. Mandeep Singh Tuteja",
    department: "Consultant Pulmonology | Fortis Jindal",
    Designation: "Pulmonology",
    status: "Full Time",
    education:"MBBS, DTCD(Pulmonology), DNB(Respiratory Medicine)",
    about :["Dr. Mandeep Singh Tuteja is a seasoned medical professional with a wealth of experience across various esteemed institutions. His journey in the field of medicine commenced in 2008 when he served as a Junior Resident at Guru Teg Bahadur Hospital in India.",

    "In 2009, Dr. Tuteja took on the role of Medical Officer at Central Jail Tihar, Delhi, where he provided essential healthcare services to incarcerated individuals, demonstrating his commitment to serving diverse populations.",
    
    "Building on his foundation, from 2012 to 2013, Dr. Tuteja undertook a Senior Residency at BLK Hospital, Delhi, further refining his clinical skills and knowledge.",
    
    "Continuing his pursuit of excellence, he served as a Senior Resident at Metro Heart & Multispeciality Hospital in Noida, Uttar Pradesh, from 2013 to 2015, contributing to the institution's reputation for exceptional patient care.",
    
    "In 2015, Dr. Tuteja transitioned into a Consultant role at Metro RLKC Hospital in Shadipur, Delhi, where he continued to offer his expertise in medical management and patient care.",
    
    "Expanding his horizons, he joined MAX Hospital in Shalimar Bagh, Delhi, as a Consultant in 2016, where he played a key role in providing specialized healthcare services to the community.",
    
    "Since 2017, Dr. Tuteja has been serving as a Pulmonology Consultant at Fortis O P Jindal Hospital & Research Center, where he specializes in respiratory health, contributing to the hospital's mission of delivering advanced medical care and innovation."],
  },
  {
    id: "dr-amesh-kumar-rajak",
    image: require("../components/Assets/Assets/doctor photos/Dr._Amesh_Kumar_Rajak__1.png"),
    name: "Dr. Amesh Kumar Rajak",
    department: "General and Laparoscopic Surgeon",
    Designation: "Gen - Surgery",
    status: "null",
    education: "MBBS, MS(Surgery)",
    about :["Dr. Amesh Kumar Rajak is a distinguished medical professional with a diverse background in healthcare. He commenced his career in 2010 as a medical officer under the Health & Family Welfare Department of the Government of Orissa, where he served diligently for two years.",

    "In 2015, Dr. Rajak furthered his expertise by undertaking a senior residency at Bhopal Memorial Hospital & Research Centre, a renowned institution known for its commitment to medical research and patient care.",
    
    "Continuing his journey, from 2016 to 2017, Dr. Rajak assumed the role of senior medical officer at NALCO Hospital under the Government of India, where he played a pivotal role in delivering quality healthcare services to the community.",
    
    "In pursuit of academic excellence, Dr. Rajak embarked on a senior residency program at AIIMS New Delhi from 2017 to 2018, solidifying his knowledge and skills in his chosen field.",
    
    "Driven by a passion for healing and a dedication to his patients, Dr. Rajak brings a wealth of experience and expertise to his practice. He is committed to providing compassionate care and evidence-based treatments to all those under his care."],
  },
  {
    id: "dr-bharti-soy",
    image: require("../components/Assets/Assets/doctor photos/Dr._Bharti_Soy_1.png"),
    name: "Dr. Bharti Soy",
    department: "Consultant Obs & Gynae | Fortis Jindal",
    Designation: "Obs & Gynae",
    status: "Full Time",
    education: "MBBS, MS(Obs & Gynae)",
    about :["Dr. Bharti Soy is a dedicated medical professional with a focus on providing quality healthcare services. Her journey began in 2013 when she served as a Senior Resident at SPS Apollo Hospital in Ludhiana, Punjab. During this period, she gained invaluable experience and expertise in her field.",

    "From 2016 to 2019, Dr. Soy continued her professional growth by working as a Junior Consultant at Suman Hospital in Ludhiana. Here, she demonstrated her commitment to patient care and further refined her skills in medical management.",
    
    "Since 2019, Dr. Soy has been serving as a Consultant at Fortis O P Jindal Hospital & Research Center in Raigarh, where she continues to contribute her knowledge and expertise to the healthcare community. With a focus on excellence and compassion, Dr. Soy strives to provide the best possible care to her patients, ensuring their well-being and satisfaction."]
  },
  {
    id: "dr-santosh-kumar-das",
    image: require("../components/Assets/Assets/doctor photos/Dr._Santosh_Kumar_Das__1.png"),
    name: "Dr. Santosh Kumar Das",
    department: "Consultant Orthopedics | Fortis Jindal",
    Designation: "Orthopedics",
    status: "Full Time",
    education: "MBBS, DNB(Orthopaedics)",
    about :["Dr. Santosh Kumar Das is a highly skilled and dedicated medical professional with a diverse background in healthcare. His career began in 2012 as a Junior Resident at Apollo Hospital in Bhubaneswar, Odisha, where he laid the foundation for his medical expertise.",

    "Continuing his journey, Dr. Das served as a Junior Resident at Nanavati Superspeciality Hospital in Mumbai from 2016 to 2019, gaining valuable experience in various medical specialties and patient care.",
    
    "In 2019, he worked as a Clinical Associate at Max Smart Superspeciality Hospital in Delhi, contributing his expertise to the hospital's healthcare initiatives.",
    
    "From 2019 to 2020, Dr. Das served as a Registrar in Orthopedics at Apollo Hospitals in Bhubaneswar, Odisha, further enriching his skills in orthopedic care.",
    
    "In 2021, he continued his professional growth by working as a Senior Resident at SCB Medical College in Cuttack, Odisha, followed by a brief tenure as a Senior Resident at KIMS / PBMH in Bhubaneswar in 2023.",
    
    "Since 2023, Dr. Das has been serving as a Consultant at Fortis O P Jindal Hospital & Research Center, bringing his extensive medical knowledge and compassionate approach to patient care. In this role, he continues to make a significant impact on the healthcare landscape, providing high-quality medical services to the community."]
  },
  {
    id: "dr-priyanka-shivappa",
    image: require("../components/Assets/Assets/doctor photos/Dr._Priyanka_Shivappa__1.png"),
    name: "Dr. Priyanka Shivappa",
    department: "Consultant ENT | Fortis Jindal",
    Designation: "ENT",
    status: "Full Time",
    education: "MBBS, MS(ENT)",
    about :["Dr. Priyanka Schilling Shivappa is a distinguished medical professional dedicated to providing exemplary healthcare services. Her journey began in 2014 as a Junior Resident at St. Stephen's Hospital in New Delhi, where she gained invaluable experience and expertise in patient care and medical management.",

    "Building on her foundation, Dr. Schilling Shivappa continued her professional growth by serving as a Senior Resident at St. Stephen's Hospital from 2019 to 2022. During this time, she further honed her clinical skills and contributed to the hospital's mission of delivering high-quality healthcare services.",
    
    "Since 2023, Dr. Schilling Shivappa has been serving as a Consultant at Fortis O P Jindal Hospital & Research Center in Raigarh. In this role, she brings her extensive medical knowledge and compassionate approach to patient care, contributing to the hospital's commitment to excellence in healthcare delivery."]
  },
  {
    id: "dr-prateek-shivappa",
    image: require("../components/Assets/Assets/doctor photos/Dr._Prateek_Shivappa__1.png"),
    name: "Dr. Prateek Shivappa",
    department: "Consultant Pathology | Fortis Jindal",
    Designation: "Pathology",
    status: "Null",
    education: "MBBS, MD(Pathology)",
    about :["Dr. Prateek Shivappa is a dedicated medical professional with a commitment to providing exceptional healthcare services. His journey began in 2015 when he served as a Junior Resident at St. Stephen's Hospital in New Delhi, where he gained valuable experience in patient care and medical management.",

    "Building on his foundation, Dr. Shivappa continued his professional growth by working as a Senior Resident at St. Stephen's Hospital from 2021 to 2022. During this time, he further honed his clinical skills and expertise in his chosen field.",
    
    "Since 2022, Dr. Shivappa has been serving as a Consultant at Fortis O P Jindal Hospital & Research Center in Raigarh. In this role, he brings his extensive medical knowledge and compassionate approach to patient care, contributing to the hospital's mission of providing high-quality healthcare services to the community."]
  },
  {
    id: "dr-aabhas-singh-sisodia",
    image: require("../components/Assets/Assets/doctor photos/Dr._Aabhas_Singh_Sisodiya__1.png"),
    name: "Dr. Aabhas Singh Sisodia",
    department: "Consultant Radiology | Fortis Jindal",
    Designation: "Radiology",
    status: "Full Time",
    education: "MBBS, MD(Radiology)",
    about :["Dr. Aabhash Singh Sisodiya is a distinguished medical professional with a focus on delivering exceptional healthcare services. He commenced his professional journey as a Senior Resident at Shankar Medical College & Hospital in Bhilai, where he acquired extensive experience in various medical specialties and patient care.",

    "Since 2022, Dr. Sisodiya has been serving as a Consultant at Fortis O P Jindal Hospital & Research Center in Raigarh, where he continues to demonstrate his commitment to excellence in healthcare delivery. In this role, he leverages his expertise and dedication to provide comprehensive medical care to patients, contributing to the hospital's mission of offering advanced and compassionate healthcare services to the community."]
  },
  {
    id: "dr-payal-gonde-ballamwar",
    image: require("../components/Assets/Assets/doctor photos/Dr._ Payal_Gonde_Ballamwar_1.png"),
    name: "Dr. Payal Gonde Ballamwar",
    department: "Consultant Ophthalmology | Fortis Jindal",
    Designation: "Ophthalmology",
    status: "Full Time",
    education: "MBBS, MS(Ophthalmology), FMRC",
    about :["Dr. Payal Gonde Ballamwar is a highly qualified ophthalmologist with extensive experience in eye care and surgery. She began her medical career as a Junior Resident in the Department of Ophthalmology at Grant Government Medical College and Sir J.J. Group of Hospitals in Mumbai from 2016 to 2019, where she developed a strong foundation in ophthalmic practices and patient care.",

           "Following her residency, Dr. Ballamwar served as an Assistant Professor at Seth GS Medical College & KEM Hospital in Mumbai from 2019 to 2020, where she was involved in both teaching and clinical practice, further honing her skills in ophthalmology.",

           "She transitioned into private practice as a Consultant Ophthalmologist at Swami Samarth Eye Clinic in Nagpur from 2020 to 2021, where she provided comprehensive eye care services and performed various ophthalmic procedures.",

           "From 2021 to 2022, Dr. Ballamwar worked as a Consultant Ophthalmologist at Eye Clinic in Secunderabad, and subsequently at Vasan Eye Care in Begumpet from 2022 to 2023, where she continued to expand her expertise in managing a wide range of eye conditions and performing surgeries.",

          "In 2023, she joined Dr. Agarwal's Eye Hospital in Hyderabad, Telangana, where she served as a Consultant Ophthalmologist, further enhancing her experience in advanced ophthalmic care and surgical techniques.",

          "Since 2024, Dr. Ballamwar has been associated with Fortis O.P. Jindal Hospital & Research Centre in Raigarh as a Consultant Ophthalmologist, where she continues to provide high-quality eye care services, focusing on both preventative eye health and advanced surgical interventions."]
  },
  {
    id: "dr-avinash-rathore",
    image: require("../components/Assets/Assets/doctor photos/Dr._Avinash_Rathore__1.png"),
    name: "Dr. Avinash Rathore",
    department: "Consultant Oral & Maxillofacial Surgeon | Fortis JIndal",
    Designation: "Oral & Maxillofacial Surgeon",
    status: "Full Time",
    education: "BDS, MDS(Oral & Maxillofacial Surgery)",
    about :["Dr. Avinash Rathore is a seasoned healthcare professional dedicated to providing exemplary medical care. Beginning his professional journey in 2019, Dr. Rathore served as a Consultant, offering his expertise through freelancing opportunities. During this time, he demonstrated his commitment to excellence and gained valuable experience in diverse healthcare settings.",

    "In 2021, Dr. Rathore joined Fortis O P Jindal Hospital & Research Center in Raigarh as a Consultant, where he continues to deliver high-quality medical services to patients. His role in this esteemed institution reflects his passion for healthcare and his commitment to making a positive impact on the well-being of individuals within the community."]
  },
  {
    id: "dr-shehdeep-chhabra-tuteja",
    image: require("../components/Assets/Assets/doctor photos/Dr._Snehdeep_Chabra_Tuteja_1.png"),
    name: "Dr. Snehdeep Chhabra Tuteja",
    department: "Consultant Dental | Fortis Jindal",
    Designation: "Dental",
    status: "Full Time",
    education: "BDS",
    about :["Dr. Snehdeep Kaur Chhabra is a proficient dental practitioner with a diverse background in providing comprehensive dental care. She began her career as an Associate Dentist at Aarogya Dental Clinic in Raipur, where she honed her skills in various dental procedures and patient care.",

    "Building on her experience, Dr. Chhabra served as an Associate Dentist at Popli Dental Clinic in Delhi, further expanding her expertise in different aspects of dentistry.",
    
    "Continuing her journey, she joined Tethwa Dental Clinic in Delhi as an Associate Dentist, where she continued to excel in delivering high-quality dental services to her patients.",
    
    "Since 2018, Dr. Chhabra has been serving as a Consultant at Fortis O P Jindal Hospital & Research Center in Raigarh, where she brings her extensive experience and dedication to providing exceptional dental care to the community. With her expertise and commitment to excellence, she continues to make a significant impact on the dental health and well-being of her patients."]
  },
  {
    id: "dr-chittranjan-haldar",
    image: require("../components/Assets/Assets/doctor photos/Dr._Chittranjan_Haldar__1.png"),
    name: "Dr. Chittranjan Haldar",
    department: "Pediatrician/ Medical Officer",
    Designation: "Pediatrician",
    status: "null",
    education: "MBBS, DCH(Pediatrics), PGDFM(Family Medicine), MBA",
    about :["Dr. Chittaranjan Haldar is a seasoned medical professional with a dedicated focus on pediatric care. His illustrious career began in 1990 as a Medical Officer in West Bengal Health Services, where he provided essential healthcare services to the community.",

    "From 1994 to 2023, Dr. Haldar served as a Pediatrician in Coal India Limited, where he played a vital role in ensuring the health and well-being of children within the organization.",
    
    "In 2024, he brought his wealth of experience and expertise to Fortis O P Jindal Hospital & Research Center, where he continues to serve as a Pediatrician. In this role, Dr. Haldar remains committed to delivering compassionate and comprehensive pediatric care, further enhancing the hospital's reputation for excellence in healthcare services."]
  },
  {
    id: "dr-dhananjay-patel",
    image: require("../components/Assets/Assets/doctor photos/Dr._Dhananjay_Patel__1.png"),
    name: "Dr. Dhananjay Patel",
    department: "Consultant Paediatrics | Fortis Jindal",
    Designation: "Paediatrics",
    status: "Full Time",
    education: "MBBS, DCH",
    about :["Dr. Dhananjay Patel is an experienced pediatrician dedicated to providing compassionate and comprehensive care for children. He began his medical career as a Junior Resident at Haria L G Rotary Hospital in Vapi, Gujarat, from 2007 to 2009, where he developed a strong foundation in pediatric medicine.",
             
            "Since 2010, Dr. Patel has been serving as a Consultant in Pediatrics at Fortis O.P. Jindal Hospital & Research Center. In this role, he continues to demonstrate his commitment to child health and well-being, offering expert care and support to his young patients and their families. His extensive experience and dedication to his profession have made him a trusted name in pediatric healthcare within the community."]
  },
  {
    id: "dr-arjun-singh",
    image: require("../components/Assets/Assets/doctor photos/Dr._Arjun_Singh__1.png"),
    name: "Dr. Arjun Singh",
    department: "Critical Care",
    Designation: "ER",
    status: "null",
    education: "MBBS, MD(Anaesthesiology, Critical Care & Pain Management), IDCCM",
    about :["Dr. Arjun Singh is a distinguished medical professional with a focus on anesthesia, intensive care, and critical care medicine. His journey began in 2018 as a Senior Resident at GSVM Medical College in Kanpur, where he developed a strong foundation in medical practice and patient care.",

    "From 2019 to 2021, Dr. Singh served as a Senior Registrar at Ruby General Hospital in Kolkata, honing his skills in anesthesia and critical care management.",
    
    "In 2021, he joined Fortis O P Jindal Hospital & Research Center as a Consultant and took charge of the Department of Anesthesia & Intensive Care Unit, showcasing his leadership abilities and commitment to providing high-quality care to patients.",
    
    "Transitioning to a consulting role, Dr. Singh worked as a Consultant at Amitdhara Superspeciality Hospital in Karnal, Haryana, from 2023 to 2024, where he continued to deliver expert medical care to patients.",
    
    "Returning to Fortis O P Jindal Hospital & Research Center in 2024, Dr. Singh assumed the role of Consultant (In Charge) - Intensivist, further demonstrating his expertise in critical care medicine. In this capacity, he oversees the intensive care unit, ensuring the highest standards of care for patients in critical condition."]
  },
  {
    id: "dr-dharamendra-choubey",
    image: require("../components/Assets/Assets/doctor photos/Dr._Dharmendra_Choubey__1.png"),
    name: "Dr. Dharamendra Choubey",
    department: "Consultant Anaesthesiology & Intensive Care Unit | Fortis Jindal",
    Designation: "Anaesthesia",
    status: "Full Time",
    education: "MBBS, MD(Anaesthesiology)",
    about :["Dr. Dharmendra Choubey is a highly accomplished medical professional with an extensive and diverse background in healthcare. His career began in 1997 as a Senior Resident at Safdarjung Hospital in New Delhi, where he laid the foundation for his medical expertise.",

    "Continuing his journey, Dr. Choubey served as a Senior Resident at R.M.L Hospital in New Delhi from 1997 to 2000, further refining his skills in patient care and medical management.",
    
    "In 2001, he embarked on an international venture, working as a Junior Specialist in the Ministry of Health Khaula Hospital in Oman until 2006, where he gained valuable experience in a different healthcare setting.",
    
    "Returning to India, Dr. Choubey served as a Consultant at Deepak Memorial Hospital in New Delhi from 2006 to 2011, contributing his expertise to the hospital's healthcare initiatives.",
    
    "In 2011, he ventured into international healthcare again, serving as a Specialist at Zulekha Hospital in Dubai, UAE, followed by roles as a Specialist at Cedar J. Ali Hospital and LLH Hospital Musaffah in Abu Dhabi from 2013 to 2016.",
    
    "From 2016 to 2021, Dr. Choubey served as a Specialist at Medeor International Hospital in Al Ain, further enriching his international medical experience.",
    
    "Returning to India in 2021, Dr. Choubey served as a Consultant at Mitali Hospital & Heart Care Centre in Uttar Pradesh until 2023, before joining Fortis O P Jindal Hospital & Research Centre in Raigarh as a Consultant. In this role, he continues to deliver exceptional medical care, leveraging his vast experience and expertise to benefit the community."]
  },
  {
    id: "dr-pushpa-patel",
    image: require("../components/Assets/Assets/doctor photos/Dr._Pushpa_Patel__1.png"),
    name: "Dr. Pushpa Patel",
    department: "Consultant Anaesthesiology & Intensive Care Unit | Fortis Jindal",
    Designation: "Anaesthesia",
    status: "Full Time",
    education: "MBBS, MD(Anaesthesia)",
    about: ["Dr. Pushpa Patel is a dedicated healthcare professional currently serving as a Consultant at Fortis O P Jindal Hospital & Research Center. With a commitment to excellence and a passion for patient care, Dr. Patel brings her expertise and compassion to her role, ensuring the delivery of high-quality medical services to the community. Through her work at Fortis O P Jindal Hospital & Research Center, Dr. Patel continues to make a meaningful difference in the lives of her patients, contributing to the hospital's mission of providing advanced and compassionate healthcare."]
  },
  {
    id: "dr-arnab-mukherjee",
    image: require("../components/Assets/Assets/doctor photos/Dr._Arnab_Mukherjee_1.png"),
    name: "Dr. Arnab Mukherjee",
    department: "CMO-ER",
    Designation: "ER",
    status: "null",
    education: "MBBS, D.Anesthesia",
    about :["Dr. Arnab Mukherjee is an experienced intensivist dedicated to providing critical care expertise in various healthcare settings. He began his journey as an Intensivist at Jupiter Hospital in Vadodara, Gujarat, from 2019 to 2020, where he demonstrated his proficiency in managing critically ill patients.",

    "In 2020, Dr. Mukherjee continued his valuable contributions to critical care by working as an Intensivist at Sangam Hospital in Bodeli, Gujarat, further enhancing his skills in intensive care medicine.",
    
    "Returning to Jupiter Hospital in Vadodara from 2021 to 2024, Dr. Mukherjee continued his role as an intensivist, providing specialized care to critically ill patients and contributing to the hospital's reputation for excellence in critical care services.",
    
    "In 2024, Dr. Mukherjee transitioned to the role of Chief Medical Officer - Emergency Room (CMO - ER) at Fortis O P Jindal Hospital & Research Center. In this capacity, he brings his extensive experience and leadership skills to oversee emergency medical services, ensuring efficient and high-quality care delivery to patients in need of urgent medical attention."]
  },
  {
    id: "dr-urchita-patel",
    image: require("../components/Assets/Assets/doctor photos/Dr._Urchita_Patel__1.png"),
    name: "Dr. Urchita Patel",
    department: "Dermatologist",
    Designation: "Dermatology",
    status: "null",
    education: "MBBS, FAM, P.G.D.C.D",
    about :["Dr. Urchita Patel is a skilled dermatologist with a passion for providing quality healthcare services. Her professional journey began in 2022, where she served as a Dermatologist at District Hospital Kondagaon, offering her expertise in skin care and treatment.",

    "Continuing her commitment to dermatology, Dr. Patel transitioned to District Hospital Kanker in 2023, where she continued to excel in diagnosing and treating various dermatological conditions, further enriching her experience in the field.",
    
    "In 2024, Dr. Patel joined Fortis O P Jindal Hospital & Research Center as a Consultant, bringing her extensive knowledge and dedication to patient care to the institution. In this role, she continues to provide exceptional dermatological services, contributing to the hospital's mission of delivering advanced and compassionate healthcare to its patients."]
  },
];

//////////////////////////// Data for specialtiesList ////////////////////////////////

export const specialtiesList = [
  {
    id: "InternalMedicine",
    img: require("../images/spec/internal_1-removebg-preview.png"),
    special: "Internal Medicine",
  },
  {
    id: "NeuroSurgery",
    img: require("../images/spec/neuro1-removebg-preview.png"),
    special: "Neuro Surgery",
  },
  {
    id: "Cardiology",
    img: require("../images/cardiology-removebg-preview.png"),
    special: "Cardiology",
  },
  {
    id: "Pulmonology",
    img: require("../images/spec/pulmonology1-removebg-preview.png"),
    special: "Pulmonology",
  },
  {
    id: "Surgery",
    img: require("../images/spec/surgery1-removebg-preview (1).png"),
    special: "Surgery",
  },
  {
    id: "Obs&Gynae",
    img: require("../images/spec/obs1-removebg-preview.png"),
    special: "Obs & Gynae",
  },
  {
    id: "Orthopedics",
    img: require("../images/spec/orthopadic_1-removebg-preview.png"),
    special: "Orthopedics",
  },
  {
    id: "ENT",
    img: require("../images/spec/ENT1-removebg-preview.png"),
    special: "ENT",
  },
  {
    id: "Radiology",
    img: require("../images/radiology-removebg-preview.png"),
    special: "Radiology",
  },
  {
    id: "Ophthalmology",
    img: require("../images/spec/ophthamology-removebg-preview.png"),
    special: "Ophthalmology",
  },
  {
    id: "Psychiatry",
    img: require("../images/spec/psychiatry-removebg-preview.png"),
    special: "Psychiatry",
  },
  {
    id: "Oral&MaxillofacialSurgeon",
    img: require("../images/spec/oral1-removebg-preview.png"),
    special: "Oral & Maxillofacial Surgeon",
  },
  {
    id: "Oncology",
    img: require("../images/spec/oncology-removebg-preview.png"),
    special: "Oncology",
  },
  {
    id: "Urology",
    img: require("../images/spec/urology1-removebg-preview.png"),
    special: "Urology",
  },
    {
    id: "DentalSurgeon",
    img: require("../images/spec/dental3-removebg-preview.png"),
    special: "Dental Surgeon",
  },
  {
    id: "Pediatrics",
    img: require("../images/spec/pediatrics-removebg-preview.png"),
    special: "Pediatrics",
  },
  {
    id: "Anaesthesiology&IntensiveCareUnit",
    img: require("../images/spec/icu-removebg-preview.png"),
    special: "Anaesthesiology & Intensive Care Unit",
  },
  {
    id: "Physiotheraphy",
    img: require("../images/spec/physiotherapy1-removebg-preview.png"),
    special: "Physiotheraphy",
  },
  {
    id: "Dermatology",
    img: require("../images/speciality.png"),
    special: "Dermatology",
  }
];

///////////////////////////// Data for specialityPage /////////////////////////////////

export const specialityPage = [
  {
    id:"InternalMedicine",
    image: require("../images/speciality-deafult.png"),
    title:"Internal Medicine",
    about: "The Internal Medicine Department offers comprehensive primary care services in an outpatient setting along with providing complete care to hospitalized patients suffering from a broad range of ailments. The department is well equipped with the latest technologies to manage acute and chronic health conditions such as diabetes and coronary disease, acute illness like respiratory tract infections and joint pains. Our expert healthcare team is comprised of Board-Certified physicians of internal medicine, geriatrics and pediatrics, nurses, medical assistants, and skilled support staff. The Department of Internal medicine doctors focus on delivering multidisciplinary care to patients of all ages. ",
},
{
  id:"NeuroSurgery",
  image: require("../images/speciality-deafult.png"),
  title:"Neuro Surgery",
  about: "Neurology is the department that analyses and treatment of issues affecting the anxious device, collectively with the thoughts, spinal wire, and nerves. A neurologist is a clinical professional who focuses on this area and is skilled in the analysis, remedy, and manipulation of numerous neurological conditions. Our brain is a complex and critical organ that controls and regulates the functioning of our body. Any harm or malfunction in this can lead to a massive variety of neurological problems, which include Parkinson's ailment, Alzheimer's sickness, epilepsy, stroke, and a couple of sclerosis. These conditions can greatly affect a person's lifestyles and require expert attention. This is in which a neurologist is utmost needed. They are particularly skilled and professional in know-how the intricacies of the involved gadget and diagnosing and treating its issues. They use various treatments, which includes imaging tests, genetic trying out, and neurological tests, to assess the affected person's circumstance and provide a remedy. ",
},
{
  id:"Cardiology",
  image: require("../images/speciality-deafult.png"),
  title:"Cardiology",
  about: "The Cardiology Department renders bronchoscopy services, both flexible and rigid. The department is well known for its competent work in interventional bronchoscopy, especially Trans-Bronchial Needle Aspiration (TBNA)/ biopsies, endobronchial electrosurgery procedures, balloon bronchoplasty and stenting. Pleuroscopy and video assisted thoracoscopic procedures add to another field of the proficiency of the department. The Pulmonology Department is also equipped with the Endobronchial Ultrasound (EBUS) facility. The department utilizes all the latest and modern tools for broncoscopic diagnosis. ",
},
  {
    id: "Pulmonology",
    image: require("../images/speciality-deafult.png"),
    title: "Pulmonology",
    about: "The Pulmonology Department renders bronchoscopy services, both flexible and rigid. The department is well known for its competent work in interventional bronchoscopy, especially Trans-Bronchial Needle Aspiration (TBNA)/ biopsies, endobronchial electrosurgery procedures, balloon bronchoplasty and stenting. Pleuroscopy and video assisted thoracoscopic procedures add to another field of the proficiency of the department. The Pulmonology Department is also equipped with the Endobronchial Ultrasound (EBUS) facility. The department utilizes all the latest and modern tools for broncoscopic diagnosis. ",
  },
  {
    id:"Surgery",
    image: require("../images/speciality-deafult.png"),
    title:"Surgery",
    about: "surgery is a cornerstone specialty in medicine that encompasses a broad range of surgical procedures concerning the diagnosis and treatment of injuries and diseases across various organs and systems in the body. The professionals who perform these procedures are called general surgeons. They are highly skilled. The word “general” shouldn’t be confused with the lack of specialty training. General surgeons are essential members of the healthcare team for the treatment of common illnesses that are cured with surgery.  ",
},
{
  id:"Obs&Gynae",
  image: require("../images/speciality-deafult.png"),
  title:"Obs & Gynae",
  about: "If you’re in search of the best gynaecology hospital in India or the best gynaecology clinics near you, consider Fortis Healthcare. As one of India’s leading healthcare providers with over 28 hospitals nationwide, Fortis offers a diverse range of medical services, including cardiology, neurology, oncology, orthopaedics, and more. Notably, Fortis excels in obstetrics and gynaecology, specialising in women’s health and reproductive system care.  ",
},
{
  id:"Orthopedics",
  image: require("../images/speciality-deafult.png"),
  title:"Orthopedics",
  about: "Orthopedics deals with the management of aliments of the musculoskeletal system. The musculoskeletal system comprises the body’s structure and the mechanics that make it work. Doctors in this field specialize in diagnosing, treating, and treating conditions affecting the bones, joints, muscles, tendons, ligaments, cartilage, and nerves in patients of all ages, from newborn babies to the elderly. The field has evolved into a multidisciplinary specialty encompassing a variety of musculoskeletal disorders, from fractures and joint injuries to degenerative conditions and congenital anomalies. It plays a significant role in restoring mobility, reducing pain, and improving the quality of life for millions globally. ",
},
{
  id:"ENT",
  image: require("../images/Ent.jpg"),
  title:"ENT",
  about: "The Department of ENT provides a broad spectrum of surgical as well as medical services for the disorders related to ear, nose, throat, head and neck. The highly skilled team of ENT specialists use the latest equipment and technologies to perform skull base surgeries, endoscopic nasal surgeries, ear and cochlear implants. The team is expert in treating patients in different age groups and with problems related to ear, nose and throat, some of which include the following: • Hearing and deafness, deviated septum, rhinitis, ringing in the ears, sinusitis, nasal obstruction, sinus headaches and migraines, and various cancerous conditions. • Sleep-related disorders such as nasal and airway obstruction, sleep-disordered breathing, sleep apnea and snoring along with expert counseling and consultations to the patient.  ",
},
{
  id:"Radiology",
  image: require("../images/radiology.jpg"),
  title:"Radiology",
  about: "The Department of Radiology offers a broad range of radiological services related to MRI, PET CT, Ultrasound, Sonography and Color Doppler, CT, Dexa, Mammography, intervention procedures, Fluoroscopy, and X-Rays. The department is equipped with technologically advanced medical equipment to render the early and accurate diagnostic output. The department consists of highly trained and experienced technicians, and skilled radiologists who are dedicated to provide 24X7 quality patient care. The department offers radiology services to patients of all age groups including the new born children, old age patients along with ambulatory patients and inpatients. The department actively pushes boundaries in the world of radiology and imaging through various research activities.",
},
{
  id:"Ophthalmology",
  image: require("../images/speciality-deafult.png"),
  title:"Ophthalmology",
  about: "The Department of Ophthalmology deals with the anatomy, structure, physiology, and diseases related to the eyes. The team offers superlative care and treatment for all aged patients for diseases such as glaucoma, cataract, age-related macular degeneration and diabetic retinopathy. The team provides expert consultation, accurate and early diagnosis and management of various problems affecting the eyes. The department is well designed to render a comprehensive range of surgical and medical eye care for preservation, protection, restoration and enhancement of vision for all aged patients. ",
},
{
  id:"Psychiatry",
  image: require("../images/speciality-deafult.png"),
  title:"Psychiatry",
  about: "Psychiatry is a medical specialty focused on the diagnosis, treatment, and prevention of mental, emotional, and behavioral disorders. Psychiatrists are medical doctors who specialize in psychiatry and are trained to evaluate, diagnose, and manage a wide range of mental health conditions.Psychiatry plays a crucial role in promoting mental wellness, addressing mental illness, and improving the quality of life for individuals affected by psychiatric disorders.  ",
},
{
  id:"Oral&MaxillofacialSurgeon",
  image: require("../images/speciality-deafult.png"),
  title:"Oral & Maxillofacial Surgeon",
  about: "An oral and maxillofacial surgeon (OMFS) is a specialized surgical specialist who deals with the diagnosis, surgical treatment, and management of conditions affecting the mouth, jaw, face, and neck. This field merges dentistry and medicine to address complex issues related to these areas. ",
},
{
  id:"Oncology",
  image: require("../images/speciality-deafult.png"),
  title:"Oncology",
  about: "One of the major reasons for the increasing impact of oncology disease in India is the lack of awareness and screening programs. Many people only seek medical help when the cancer has reached an advanced stage, making it difficult to treat. This delay in diagnosis and treatment often leads to a poor prognosis and a higher risk of mortality. However, Fortis Healthcare is considered as the Best Medical Oncology Treatment in India because it is involved in creating awareness about cancer and the importance of early detection through various initiatives and awareness programs.",
},
{
  id:"Urology",
  image: require("../images/speciality-deafult.png"),
  title:"Urology",
  about: "The Department of Urology at Fortis delivers advanced and specialized urological care at par with international standards. The department provides a full spectrum of diagnostics and treatment for urology cancers, urinary tract infectious diseases, renal disease, reconstructive urology surgery, laparoscopic urology, erectile dysfunction,  stone disease, pediatric urology, prostate diseases, pediatric renal transplant and andrology. The urologists are experts in treating patients with the latest and updated laparoscopic and laser technologies. The department delivers multi-disciplinary and compassionate care to the patients suffering from any kind of urologic disorders, including cancers, incontinence, impotence, laparoscopic urology, bladder issues and deformities. ",
},
  {
      id:"DentalSurgeon",
      image: require("../images/speciality-deafult.png"),
      title:"Dental Surgeon",
      about: "Dental science focuses on the study, diagnosis, prevention, and treatment of oral health issues related to the teeth, gums, mouth, and supporting structures. It involves various disciplines and specialties within dentistry, aimed at maintaining oral health and promoting overall well-being. On the other hand, dental health is associated with the condition of the mouth, teeth, gums and other related structures. There is more to dental health and the sooner you know about it, the better you could prevent it from any harm. Best dental hospitals and Fortis healthcare have always advocated about adopting good oral hygiene practices for the well being of the mouth. Top dental clinics in India have always been stressing about regular dental check ups and healthy diet. ",
  },
  {
    id:"Pediatrics",
    image: require("../images/speciality-deafult.png"),
    title:"Pediatrics",
    about: "Pediatrics is the branch of medicine that focuses on the health and medical care of infants, children, and adolescents from birth up to the age of 18-21 years, depending on the country. Pediatricians are medical doctors who specialize in providing healthcare for children and adolescents, addressing their unique physical, emotional, and developmental needs. ",
},
  {
      id:"Anaesthesiology&IntensiveCareUnit",
      image: require("../images/speciality-deafult.png"),
      title:"Anaesthesiology & Intensive Care Unit",
      about: "Anesthesiology and Intensive Care Unit (ICU) are two closely related medical specialties that focus on providing critical care to patients, particularly those undergoing surgery or facing life-threatening medical conditions.Both anesthesiology and intensive care medicine require specialized training and expertise to manage complex medical situations and provide high-quality care to patients in critical condition. ",
  },
  {
      id:"Physiotheraphy",
      image: require("../images/speciality-deafult.png"),
      title:"Physiotheraphy",
      about: "Physiotherapy, also known as physical therapy, is a healthcare profession focused on optimizing movement and function. Physiotherapists work with individuals of all ages who have medical conditions, illnesses, or injuries that limit their ability to move and perform functional activities in their daily lives. Physiotherapists use a variety of techniques and interventions tailored to the specific needs of each patient. ",
  },
  {
      id:"Dermatology",
      image: require("../images/speciality-deafult.png"),
      title:"Dermatology",
      about: "All Dermatology Hospitals in India with Fortis provide comprehensive diagnostics and treatments for healthy skincare. The Dermatology Hospital department offers a wide variety of services in medical, pediatric, surgical and cosmetic dermatology. The dermatologists are experts in successfully treating all forms of skin-related disfigurements including melanomas, skin cancers, acne and various other problems like lupus, bullous pemphigoid and pemphigus vulgaris. The team renders excellent diagnoses and management of conditions affecting hair and nails.   ",
  }
];
// ///////////////////////////// Data for blogPage /////////////////////////////////////////////

// export const blogPage = [
//   {
//     id: "1",
//     image: require("../images/Fortis Blog Banner.jpg"),
//     // title: "Pulmonology",
//     about: "A blog is an informational website consisting of discrete, often informal diary-style text entries (posts). Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page. Until 2009, blogs were often the work of a single individual, occasionally of a small group, and often covered a single subject or topic. In the 2010s, multi-author blogs (MABs) emerged, featuring the writing of multiple authors and sometimes professionally edited. MABs from newspapers, other media outlets, universities, think tanks, advocacy groups, and similar institutions account for an increasing quantity of blog traffic. The rise of Twitter and other microblogging systems helps integrate MABs and single-author blogs into the news media. Blog can also be used as a verb, meaning to maintain or add content to a blog. ",
//   },
//   // {
//   //     id:"2",
//   //     image: require("../images/Fortis Blog Banner.jpg"),
//   //     // title:"Neuro Surgery",
//   //     about: "A blog is an informational website consisting of discrete, often informal diary-style text entries (posts). Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page. Until 2009, blogs were often the work of a single individual, occasionally of a small group, and often covered a single subject or topic. In the 2010s, multi-author blogs (MABs) emerged, featuring the writing of multiple authors and sometimes professionally edited. MABs from newspapers, other media outlets, universities, think tanks, advocacy groups, and similar institutions account for an increasing quantity of blog traffic. The rise of Twitter and other microblogging systems helps integrate MABs and single-author blogs into the news media. Blog can also be used as a verb, meaning to maintain or add content to a blog. ",
//   // },
//   // {
//   //     id:"3",
//   //     image: require("../images/Fortis Blog Banner.jpg"),
//   //     // title:"Dental Surgeon",
//   //     about: "A blog is an informational website consisting of discrete, often informal diary-style text entries (posts). Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page. Until 2009, blogs were often the work of a single individual, occasionally of a small group, and often covered a single subject or topic. In the 2010s, multi-author blogs (MABs) emerged, featuring the writing of multiple authors and sometimes professionally edited. MABs from newspapers, other media outlets, universities, think tanks, advocacy groups, and similar institutions account for an increasing quantity of blog traffic. The rise of Twitter and other microblogging systems helps integrate MABs and single-author blogs into the news media. Blog can also be used as a verb, meaning to maintain or add content to a blog. ",
//   // },
//   // {
//   //     id:"4",
//   //     image: require("../images/Fortis Blog Banner.jpg"),
//   //     // title:"Orthopedics",
//   //     about: "A blog is an informational website consisting of discrete, often informal diary-style text entries (posts). Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page. Until 2009, blogs were often the work of a single individual, occasionally of a small group, and often covered a single subject or topic. In the 2010s, multi-author blogs (MABs) emerged, featuring the writing of multiple authors and sometimes professionally edited. MABs from newspapers, other media outlets, universities, think tanks, advocacy groups, and similar institutions account for an increasing quantity of blog traffic. The rise of Twitter and other microblogging systems helps integrate MABs and single-author blogs into the news media. Blog can also be used as a verb, meaning to maintain or add content to a blog. ",
//   // },
//   // {
//   //     id:"5",
//   //     image: require("../images/Fortis Blog Banner.jpg"),
//   //     // title:"Cardiology",
//   //     about: "A blog is an informational website consisting of discrete, often informal diary-style text entries (posts). Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page. Until 2009, blogs were often the work of a single individual, occasionally of a small group, and often covered a single subject or topic. In the 2010s, multi-author blogs (MABs) emerged, featuring the writing of multiple authors and sometimes professionally edited. MABs from newspapers, other media outlets, universities, think tanks, advocacy groups, and similar institutions account for an increasing quantity of blog traffic. The rise of Twitter and other microblogging systems helps integrate MABs and single-author blogs into the news media. Blog can also be used as a verb, meaning to maintain or add content to a blog. ",
//   // },
//   // {
//   //     id:"6",
//   //     image: require("../images/Fortis Blog Banner.jpg"),
//   //     // title:"Obs & Gynae",
//   //     about: "A blog is an informational website consisting of discrete, often informal diary-style text entries (posts). Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page. Until 2009, blogs were often the work of a single individual, occasionally of a small group, and often covered a single subject or topic. In the 2010s, multi-author blogs (MABs) emerged, featuring the writing of multiple authors and sometimes professionally edited. MABs from newspapers, other media outlets, universities, think tanks, advocacy groups, and similar institutions account for an increasing quantity of blog traffic. The rise of Twitter and other microblogging systems helps integrate MABs and single-author blogs into the news media. Blog can also be used as a verb, meaning to maintain or add content to a blog. ",
//   // },
//   // {
//   //     id:"7",
//   //     image: require("../images/Fortis Blog Banner.jpg"),
//   //     // title:"Radiology",
//   //     about: "A blog is an informational website consisting of discrete, often informal diary-style text entries (posts). Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page. Until 2009, blogs were often the work of a single individual, occasionally of a small group, and often covered a single subject or topic. In the 2010s, multi-author blogs (MABs) emerged, featuring the writing of multiple authors and sometimes professionally edited. MABs from newspapers, other media outlets, universities, think tanks, advocacy groups, and similar institutions account for an increasing quantity of blog traffic. The rise of Twitter and other microblogging systems helps integrate MABs and single-author blogs into the news media. Blog can also be used as a verb, meaning to maintain or add content to a blog. ",
//   // },
//   // {
//   //   id:"8",
//   //   image: require("../images/Fortis Blog Banner.jpg"),
//   //   // title:"Oral & Maxillofacial Surgeon",
//   //   about: "A blog is an informational website consisting of discrete, often informal diary-style text entries (posts). Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page. Until 2009, blogs were often the work of a single individual, occasionally of a small group, and often covered a single subject or topic. In the 2010s, multi-author blogs (MABs) emerged, featuring the writing of multiple authors and sometimes professionally edited. MABs from newspapers, other media outlets, universities, think tanks, advocacy groups, and similar institutions account for an increasing quantity of blog traffic. The rise of Twitter and other microblogging systems helps integrate MABs and single-author blogs into the news media. Blog can also be used as a verb, meaning to maintain or add content to a blog. ",
//   // },
// ];

// //////////////////////////// Data for blogsList /////////////////////////////////////////

// export const blogsList = [
//   {
//     id: 1,
//     title: "BLOG",
//     img: require("../images/Fortis Blog Banner.jpg"),
//   },
//   {
//     id: 2,
//     title: "BLOG",
//     img: require("../images/Fortis Blog Banner.jpg"),
//   },
//   {
//     id: 3,
//     title: "BLOG",
//     img: require("../images/Fortis Blog Banner.jpg"),
//   },
//   {
//     id: 4,
//     title: "BLOG",
//     img: require("../images/Fortis Blog Banner.jpg"),
//   },
//   {
//     id: 5,
//     title: "BLOG",
//     img: require("../images/Fortis Blog Banner.jpg"),
//   },
//   {
//     id: 6,
//     title: "BLOG",
//     img: require("../images/Fortis Blog Banner.jpg"),
//   },
//   {
//     id: 7,
//     title: "BLOG",
//     img: require("../images/Fortis Blog Banner.jpg"),
//   },
//   {
//     id: 8,
//     title: "BLOG",
//     img: require("../images/Fortis Blog Banner.jpg"),
//   },
// ];

////////////////////////////////////// Data for Stories ////////////////////////////////////////

export const videos = [
  {
    id: 1,
    video: "https://www.youtube.com/embed/AdTIMnkgXDo",
  },
  {
    id: 2,
    video: "https://www.youtube.com/embed/xidUYv1r0w0",
  },
  {
    id: 3,
    video: "https://www.youtube.com/embed/S9PdFAIWndg",
  },
  {
    id: 4,
    video: "https://www.youtube.com/embed/_fba30qypKs",
  },
  {
    id: 5,
    video: "https://www.youtube.com/embed/VJDxdGsY9ho",
  },
  
];

//------------------------------------service page img-----------------------------

export const infaImage = [
  {
    id: 1,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/DSC_2387.JPG"),
  },
  {
    id: 2,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/DSC_2381.JPG"),
  },
  {
    id: 3,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/DSC_2380.JPG"),
  },
  {
    id: 4,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/OT 1 FOPJHRC.jpg"),
  },
  {
    id: 5,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/OT 2 FOPJHRC.jpg"),
  },
  {
    id: 6,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/CT SCAN FOPJHRC.jpg"),
  },
  {
    id: 7,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/Pathology FOPJHRC.jpg"),
  },
  {
    id: 8,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/Cath Lab Cardiac OT FOPJHRC.jpg"),
  },
  {
    id: 9,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/Single Room FOPJHRC.jpg"),
  },
  {
    id: 10,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/Suit Room FOPJHRC.jpg"),
  },
  {
    id: 11,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/BURN ICU.jpg"),
  },
  {
    id: 12,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/CARDIAC & NEURO OT.jpg"),
  },
  {
    id: 13,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/CARDIAC ICU.jpg"),
  },
  {
    id: 14,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/Dyalisis 1.JPG"),
  },
  {
    id: 15,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/EMERGENCY.jpg"),
  },
  {
    id: 16,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/PRIVATE ROOM 1.jpg"),
  },
  {
    id: 17,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/children.jpg"),
  },
  {
    id: 18,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/general.jpg"),
  },
  {
    id: 19,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/DSC_2352.JPG"),
  },
  {
    id: 20,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/DSC_2370.JPG"),
  },
  {
    id: 21,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/DSC_2373.JPG"),
  },
  {
    id: 22,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/DSC_2388.JPG"),
  },
  {
    id: 23,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/DSC_3684.JPG"),
  },
  {
    id: 24,
    title: "BLOG",
    img: require("../components/Assets/Assets/infasImage/DSC_2374.JPG"),
  },
];

/////////////////////////// Data for Careers Page /////////////////////////////////////

export const careerData = [
  {
    id: 1,
    title: "Infection Control Nurse (ICN)",
    qualification: "B.sc/M.sc (Nursing)",
    experience: "Minimum experience 5 to 8 Years",
    preference: "Female is Preferable",
  },
  {
    id: 2,
    title: "Quality Assurance Executive/Supervisor",
    qualification: "Graduation/MHA/Certified Auditor from NABH,AACI,AHPI/JCI",
    experience: "Minimum experience 3 to 5 Years",
  },
  {
    id: 3,
    title: "Operation Theater - Senior Supervisor/Technicians",
    qualification: "OT Tech, B.sc/M.sc",
    experience: "Minimum experience of 10 to 15 Years from multispecialty hospital",
  },
  {
    id: 4,
    title: "Emergency Technicians",
    qualification: "Diploma/Degree",
    experience: "",
  },
  {
    id: 5,
    title: "Clinical Psychologist",
    qualification: "M.Phil./PhD/MSW",
    experience: "Minimum experience of 10 Years from hospital",
    preference: "Female is Preferable",
  },
  {
    id: 6,
    title: "Sr. Executive in Stores & Purchase",
    qualification: "Graduation/Post Graduation",
    experience: "Minimum experience 5 to 8 Years in the same field",
  },
  {
    id: 7,
    title: "Sr. Executive/Executive in Sales & Marketing (Recovery)",
    qualification: "Graduation/Post Graduation",
    experience: "Minimum experience 3 to 5 Years in the same field",
  },
];