import { doctorsList } from '../../dataForAll/data'
import './doctorslist.css'
import { Link } from 'react-router-dom';
import doca from '../../components/Assets/Assets/new/DoctorsPhoto.jpeg'

export default function DoctorsList() {
  return (
    <section className='main-container'>
    <div className='imgForSpac'>
      <img src={doca} alt='banner'  width="100%" />
     </div>
     <br/>
     <br/>
     
     <div className="title">
          <span>Our Team Experts</span>
        </div>
          <div className='doctorslist-card-content'>
     {doctorsList.map((item) => {
        return(
          <Link to={`/doctorslist/${item.id}`} key={item.id}>

          <div className="doctorslist-card">
    <div className="doctorslist-card-image">
	    <img src={item.image} alt="dev" />
	</div>
    <div className="doctorslist-card-name">{item.name}</div>
    <div className="doctorslist-card-department">{item.department}</div>
    <div className="doctorslist-card-designation">{item.Designation}</div> 
  </div>
         </Link>
     )})}
         </div>

      
    </section>  
  )
}



