import React from "react";
import { videos } from "../../dataForAll/data";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./stories.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export const Stories = () => {
  const slider = React.useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint:  2000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return(
    <section className="storSec">
  
    <div className="slides-title">
    <div className="title">
    <span>Our Stories</span>
    </div>
          <Link to={'https://www.youtube.com/@fopjhrc'} className="view-all" target="blank">View All</Link>
        </div>
    <div className='stories-card-container'>
     <Slider ref={slider} {...settings}>
     {videos.map((item) => {
        return(
         <div className='stories-card' key={item.id}>
           <iframe 
                className="stories-iframe"
                src={item.video} type="video/mp4">
             </iframe>
           </div>
     )})}
      </Slider>
      <div className="navigator-btn">
        <button className="navigator-prev" onClick={() => slider?.current?.slickPrev()}><FontAwesomeIcon icon={faChevronLeft} /></button>
        <button className="navigator-next" onClick={() => slider?.current?.slickNext()}><FontAwesomeIcon icon={faChevronRight} /></button> 
      </div> 
    </div>   
    </section>
  )
}
export default Stories
