import Service from '../../components/Service/Service'
import './achievement.css'

import Gal from '../../components/Service/Gal';
import Infa from '../../components/Service/Infa';
import Specialties from '../../components/specialties/Specialties';


const Achievement = () => {
  return (
    <section className='main-container'>
    
    <Service/>
    <Specialties/>
    <Infa/>
    <Gal/>
   
    
    </section>
  )
}

export default Achievement