import './service.css'
import Doctor from "../Assets/Assets/new/service.jpg" ;


const Service = () => {

  return (
     <>
     <section className='speciality-page'>
     
        
         <div className='speciality-detail' >
        <div className='service-img'> <img src={Doctor}  alt='image' width="100%" /> 
          
        </div>
        

        {/* <div className='about-speciality'>
        
          <p>Hospital shall provide complete information to all patients & their attendants
                    who match with the available resources.
                    The Front Office Assistants (FOA's) at the Front Desk are primarily
                    responsible for dissemination of the relevant information.
                    FOPJHRC maintains relationships with other similar organizations / facilities  in order to provide patients with the appropriate care when such resources  are not available at FOPJHRC.
                    Such patients are then transferred to other hospitals following the transfer protocols.
                    We initiated a new scheme JSP Jan Aarogya Sewa Yojana for BPL, Ayushman Card Holders, Poor & Community People. 
           </p>
        </div> */}
      </div>
        
     </section>

    </>
  )
}

export default Service