import './doctorslist.css'
import { doctorDetails } from '../../dataForAll/data' 

import { useParams } from 'react-router-dom'


import Doctors from "../../components/doctors/Doctors.jsx"


const DoctorDetails = () => {
  const { id } = useParams()

  const doctora = doctorDetails.find(doctor => doctor.id === id)
  return (
    <section className='main-container'>
      
      <section className='doctor-page'>
        <div className='doctor-detail' key={doctora.id}>
          <div className='doctor-profile'> 
            <div className='doctor-profile-left'>
              <img src={doctora.image} alt='image'/>
            </div> 
            <div className='doctor-profile-right'>
              <p className='doctor-name'>{doctora.name}</p>
              <p className='doctor-department'>{doctora.department}</p>
            </div>
          </div>
          <div className='doctor-detail-right'>
            <div className='experience'>
              <p>Designation: {doctora.Designation}</p>
            <p>Timing: {doctora.status}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="main-content">
        <div className="left-part">
        <div className="about">
            <div className="about-text">About</div>
            <p className="content">{doctora.about}</p>
          </div>
          <div className="right-part">
            <div className="heading">Education</div>
            <p className="content">{doctora.education}</p>
          </div>
        </div>
      </section>
      <Doctors/>
      
    </section>
  )
}

export default DoctorDetails