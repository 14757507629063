import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './navbar.css'
import profile1 from "../components/Assets/Assets/logo_jin.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import en from '../images/em.png'

export const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleToggleNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="nav-content">
        <NavLink to="/">
          <img src={profile1} alt="Logo" className="nav-logo" onClick={handleToggleNavbar}/>
        </NavLink>
        <div
          className={`menu-icon ${showNavbar ? "active" : ""}`}
          onClick={handleToggleNavbar}
        >
          {showNavbar ? <IoCloseSharp /> : <GiHamburgerMenu />}
        </div>
        <div className={`nav-elements ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink to="/doctorslist" onClick={handleToggleNavbar}>Find a Doctor</NavLink>
            </li>
            <li>
              <NavLink to="/aboutus" onClick={handleToggleNavbar}>AboutUs</NavLink>
            </li>
            <li>
              <NavLink to="/specialtieslist" onClick={handleToggleNavbar}>Specialties</NavLink>
            </li>
            <li>
              <NavLink to="/services" onClick={handleToggleNavbar}>Services</NavLink>
            </li>
            <li>
              <NavLink to="/careers" onClick={handleToggleNavbar}>Careers</NavLink>
            </li>
            <li>
              <NavLink to="/ContactUs" onClick={handleToggleNavbar}>ContactUs</NavLink>
            </li>
            {/* <li className="emergency-btn">
              <div className="popupM">
                <Popup trigger={<button>Emergency 24x7</button>} modal nested>
                  {(close) => (
                    <div className="modal">
                      <div className="emBanner">
                        <img src={en} alt="image" />
                      </div>
                      <div className='banner-close'>
                        <button onClick={() => close()}><IoCloseSharp /></button>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;