import { specialtiesList } from "../../dataForAll/data";
import "./specialtieslist.css";

import { Link } from "react-router-dom";
import spac from '../../components/Assets/Assets/new/spac.jpg'
export default function SpecialtiesList() {
  return (
    <section className="main-container">
      
      <div className='imgForSpac'>
      <img src={spac} alt='image'  width="100%" />
     </div>
     <div className="title">
    <span>Our Specialties</span>
    </div>

      <div className="specialtieslist-card-content">
        {specialtiesList.map((item) => (
          <Link to={`/specialtieslist/${item.id}`} key={item.id}>
            <div className="specialtieslist-card">
              <img
                src={item.img}
                alt="product-img"
                className="specialtieslist-card-img"
              />
              <p className="specialtieslist-card-name">{item.special}</p>
            </div>
          </Link>
        ))}
      </div>

      
    </section>
  );
}
