import './careers.css'
import { careerData } from "../../dataForAll/data";
import { NavLink } from "react-router-dom";

const Careers = () => {
  return (
    <section className="main-container">
      <p className='container-top'>Lead Your Career With India's Leading Healthcare Network <br/> <span>at Fortis O.P. Jindal Hospital, Raigarh, Chhattisgarh.</span></p>
     <div className="title">
    <span>Open Positions</span>
    </div>
       <div className='career-card-content'>
        {careerData.map((item) => (
            <div className="career-card" key={item.id}>
              <p className="career-card-title">{item.title}</p>
              <p className="career-card-qualification">{item.qualification}</p>
              <p className="career-card-experience">{item.experience}</p>
              <p className="career-card-preference">{item.preference}</p>
              <NavLink to="https://forms.gle/W8rT9y8TZkq6Fe196" target="_blank">
              <div className='profile-btn'>
                 <button>Apply Now</button> 
              </div>
              </NavLink>
            </div>
            
        ))}
        </div>

      <div className='container-bottom'>
        <p>* Salary: Negotiable and No bar for right candidate. </p>
        <p>* Special consideration for experience applicants.</p> 
      </div>
      
    </section>
  )
}

export default Careers
