import React from "react";
import DoctorCard from "./DoctorCard";
import profile1 from "../Assets/Assets/doctor photos/Mr._Prem_Nath_Sahu__1.png";
import profile2 from "../Assets/Assets/doctor photos/Dr._Ajay_Gupta__1.png";
import profile3 from "../Assets/Assets/doctor photos/Dr._Lokesh_Mahendra_1.png";
// import profile3 from "../Assets/Assets/profile-3.png";
// import profile4 from "../Assets/Assets/profile-4.png";
import "../Styles/Doctors.css";

function Doctors() {
  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Meet Our Doctors</span>
        </h3>

        <p className="dt-description">
          Meet our exceptional team of specialist doctors, dedicated to
          providing top-notch healthcare services at Fortis OP Jindal Hospital & Research Centre. Trust in their
          knowledge and experience to lead you towards a healthier and happier
          life.
        </p>
      </div>

      <div className="dt-cards-content">
        <DoctorCard
          img={profile1}
          name="Dr. Premnath Sahu"
          title="Chief Operating Officer"
        />
        <DoctorCard
          img={profile2}
          name="Dr. Ajay Kumar Gupta"
          title="Medical Director"
        />
        <DoctorCard
          img={profile3}
          name="Dr. Lokesh Mahendra"
          title="Medical Superintendent"
        />
        {/* <DoctorCard
          img={profile3}
          name="Dr. Jenny Wilson"
          title="Endocrinologists"
        />
        <DoctorCard
          img={profile4}
          name="Dr. Albert Flores"
          title="Hematologists"
        /> */}
      </div>
    </div>
  );
}

export default Doctors;
