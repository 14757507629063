import React from "react";
import InformationCard from "./InformationCard";
import { faHeartPulse, faTruckMedical, faTooth } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
          We bring healthcare to your convenience, offering a comprehensive
          range of on-demand medical services tailored to your needs. Our
          platform allows you to connect with experienced doctors who
          provide expert medical advice, issue prescriptions, and offer
          quick refills whenever you require them.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Emergency Care"
          description="Our Emergency Care service is designed to be your reliable support
            in critical situations. Whether it's a sudden illness, injury, or
            any medical concern that requires immediate attention, our team of
            dedicated healthcare professionals is available 24/7 to provide
            prompt and efficient care."
          icon={faTruckMedical}
        />

        <InformationCard
          title="Special Program " 
          description="Free treatment is provided by JSP Jan Arogyam Yojana for Below Poverty Line(BPL) like Free angiography, Free caesarean
           / normal delivery, Free cataract, hydrocele & hernia surgery under CSR."
          icon={faHeartPulse}
        />

        <InformationCard
          title="Our Tie-Ups"
          description=" We provide free treatment facilities under Ayushman Bharat, BSKY, ESIC and other government Schemes.
          We have also empanelled with NTPC, Coal India (MCL, SECl), Adani, MSP, RKM, SKS and all the corporate as well as all the TPA companies."
          icon={faHeartPulse}
        />
      </div>
    </div>
  );
}

export default Info;
