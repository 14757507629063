import React from "react";
import InformationCard from "../aboutCompo/InformationCard";
import { faHeartPulse, faTruckMedical, faTooth, faMountainCity,faBedPulse,faKitMedical} from "@fortawesome/free-solid-svg-icons";
import "./infa.css";

function Infa() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <div className="info-title">
          <span>INFRASTRUCTURE & FACILITIES</span>
        </div>
        <p className="info-description">
        The hospital is neither limited by its size nor location and provides best in class 
        treatment & hospitality services at par with bigger hospitals in larger towns

        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Beds: 100"
          description="ICU (Medical/ Surgical/ Cardiac/ Burns/ Neonatal), Single Rooms (incl. Suite), Twin Sharing Wards, General Wards, Nursery."
          icon={faBedPulse}
        />

        <InformationCard
          title="Infrastructure " 
          description="Operation Theatre: 4 (Specialty 1, General 2, Eye 1)
          , Cath Lab
          , Minor Procedure Room.
          Dialysis
          , Recovery Unit
          Only Apheresis Machine Unit.
          4 bedded Emergency with 2 bedded Observation
          , Minor OT
          ,	Blood & Component Bank (ISO recognized & only one in region to offer Component facility)
          ,	Pharmacy
          ,	Ambulance (ACLS)"
          icon={faMountainCity}
          />

        <InformationCard
          title="Treatment Facilities 16 Specialties:"
          description="Medicine, General & Lap Surgery, Orthopedics, Obstetrics, 
          Pediatrics & Neonatology, Psychiatric, Ophthalmology, ENT, Anesthesia, Critical Care, 
          Preventive Medicine, Radiology, Pathology, Dietetics, Dentistry, Physiotherapy
          "
          icon={faKitMedical}
        />
        <InformationCard
          title="Treatment Facilities Super Specialty:"
          description="Cardiology, Cardiac Surgery, Neurosurgery, Oncology, Gastroenterologist & Urology
           (visiting) Maxillofacial Surgery, Joint Replacement and Trauma Surgery (With these, Raigarh has status
             of being 3rd City in Chhattisgarh to offer these super specialty treatment services)"
          icon={faKitMedical}
        />
        <InformationCard
          title="Diagnostic Facilities" 
          description="Radiology: 64 Slice CT Scan to carry out CT Angiograms & conventional CT scans , 4D Ultrasound, Color Doppler, Digital & Conventional X-ray
          , Lab Medicine: Biochemistry, Hematology, Histopathology, Microbiology
          , Non Invasive Cardiology: ECHO, TMT, Holter, ECG
          , Others: Audiometry, Spirometry"
          icon={faHeartPulse}
        />
      </div>
    </div>
  );
}

export default Infa;
