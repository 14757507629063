import './home.css'
import HeroSection from '../../components/HeroSection.jsx'
import Doctors from "../../components/doctors/Doctors.jsx"
import Specialties from "../../components/specialties/Specialties";
import { Stories } from '../../components/Stories/Stories.jsx';
import Reviews from '../../components/aboutCompo/Reviews.js';
import ServCard from '../../components/scard/ServCard.jsx';




const Home = () => {
  return (
    <section className='main-container'> 
    <HeroSection />
    <ServCard/>
    <Doctors />
    <Specialties />
    <Stories/>
    {/* <Blogs /> */}
    <Reviews/>
    
    </section>
  )
}

export default Home 