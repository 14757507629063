import React from "react";
import './doctors.css'
import { doctorsList } from '../../dataForAll/data';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

function Doctors() {  
  const slider = React.useRef(null);
  
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return(
    <section className="docSec">

    <div className="slides-title">
    <div className="title">
    <span>Our Doctors</span>
    </div>
          <Link to={'/doctorslist'} className="view-all">View All</Link>
        </div>
    <div className='doctors-card-container'>
     <Slider ref={slider} {...settings}>
     {doctorsList.map((item) => {
        return(
          
         <div className='doctors-card' key={item.id}>
         <div className='doctors-content'>
            <div className='doctors-card-img'>
             <img src={item.image} />
            </div>
            <div className='doctors-contentbx'>
            <p className='doctors-card-name'>  {item.name}  </p>
            <p className='doctors-card-title'>  {item.department}  </p>
            
            <Link to={`/doctorslist/${item.id}`} >
              <div className='profile-btn'>
                 <button>View Profile</button> 
              </div>
           </Link>
           </div>
         </div>
         </div>

     )})}
      </Slider>
      <div className="navigator-btn">
        <button className="navigator-prev" onClick={() => slider?.current?.slickPrev()}><FontAwesomeIcon icon={faChevronLeft} /></button>
        <button className="navigator-next" onClick={() => slider?.current?.slickNext()}><FontAwesomeIcon icon={faChevronRight} /></button> 
      </div>
    </div>  
    </section>
  )
}

export default Doctors