import React from "react";
import "./footer.css";
import { FaLinkedinIn, FaFacebookF, FaInstagram, FaXTwitter } from "react-icons/fa6";
import profile1 from "./Assets/Assets/logo_jin.png";
import { NavLink, Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
            <img src={profile1} alt="Logo" className="logo" />
            </p>
            <p className="ft-description">
               Jindal Education And Welfare Society
            </p>
            <p className="ft-description">
               Kharsia Rd, Kokaditarai, Chhattisgarh 496001
            </p>
            <p className="ft-description">
               Phone: +91 9981462800
               Phone: 07762-227033
               Phone: 07762-227034
            </p>
            <p className="ft-description ft-mail">
               Email: front.office.rgh@jindalsteel.com
            </p>
          </div>

         
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Services</p>
          <ul className="ft-list-items">
            <li>
            <NavLink to="/doctorslist" className="footer-link footer-right">
            Find a Doctor
            </NavLink>
            </li>
            <li>
            <NavLink to="/aboutus" className="footer-link footer-right">
            AboutUs
          </NavLink>
            </li>
            <li>
            <NavLink to="/specialtieslist" className="footer-link footer-right">
            Specialties
          </NavLink>
            </li>
            <li>
            <NavLink to="/services" className="footer-link footer-right">
            Services
          </NavLink>
            </li>
            <li>
            <NavLink to="/careers" className="footer-link footer-right">
            Careers
          </NavLink>
            </li>
            <li>
            <NavLink to="/ContactUs" className="footer-link footer-right">
            ContactUs
          </NavLink>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:front.office.rgh@jindalsteel.com" className="list-items-mail">front.office.rgh@jindalsteel.com</a>
            </li>
            <li>
              <a href="mailto:marketing.fopjhrc.rgh@jindalsteel.com" className="list-items-mail">
                marketing.fopjhrc.rgh@jindalsteel.com
              </a>
            </li>
            <li>
              <a href="tel:+91 9981462800">+91 9981462800</a>
            </li>
            <li>
              <a href="tel:+91 8827478405">+91 8827478405</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2024 www.opjhrcrgh.in All rights reserved.</p>
        
        <ul className="ft-social-links">
          <li>
          <NavLink to="https://www.linkedin.com/in/fortis-o-p-jindal-hospital-and-research-centre-4386a4213?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank">
          <FaLinkedinIn />
          </NavLink>
          </li>

          <li>
          <NavLink to="https://www.facebook.com/fopjhrc?mibextid=ZbWKwL" target="_blank">
          <FaFacebookF />
          </NavLink>
          </li>

          <li>
          <NavLink to="https://www.instagram.com/fopjhrc?utm_source=qr&igsh=MWlqN2RwaXYzdXhzYQ==" target="_blank">
          <FaInstagram />
          </NavLink>
          </li>

          <li>
          <NavLink to="https://twitter.com/FortisJindalrgh?t=8hyZ4tvZzWOQ67h_AvV3eA&s=09" target="_blank">
          <FaXTwitter />
          </NavLink>
          </li>
          
        </ul>
        {/* <div className="ft-copyright">
          <Link to="/developers" className="footer-link footer-right">
          Developers
          </Link>
      </div> */}
      </div>
      
    </div>
  );
}

export default Footer;
